<template>
  <div class="modal-signin">
    <mkp-image :lazy-src="url" class="img-connection" />

    <h1 class="modal-connection-title">{{ $t("forms.connection.title") }}</h1>

    <form class="form-connection" novalidate @submit.prevent="submit">
      <mkp-email-component
        v-model="form.email"
        :label="$t('forms.connection.email.label')"
        :v="$v.form.email"
        placeholder="forms.connection.email.placeholder"
        class="container-email"
      />

      <mkp-password-component
        v-model="form.password"
        :label="$t('forms.connection.password.label')"
        :v="$v.form.password"
        placeholder="forms.connection.password.placeholder"
        class="container-password"
      />
      <mkp-button class="submit-connection" @click="submit">
        {{ $t("forms.connection.button") }}
      </mkp-button>
    </form>

    <mkp-link class="reset-password" @click="changeStep('resetPassword')">
      {{ $t("forms.connection.links.resetPassword") }}
    </mkp-link>

    <mkp-link class="link-signup" @click="changeStep('singup')">
      {{ $t("forms.connection.links.singup") }}
    </mkp-link>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import MkpPasswordComponent from "../inputsForm/mkp-password-component";
import MkpImage from "../../../simple/image/mkp-image";
import MkpEmailComponent from "../inputsForm/mkp-email-component";
import MkpButton from "../../../simple/button/mkp-button";
import MkpLink from "../../../simple/link/mkp-link";
import "./mkp-connection-form.scss";

export default {
  name: "MkpConnectionForm",
  components: {
    MkpLink,
    MkpButton,
    MkpEmailComponent,
    MkpImage,
    MkpPasswordComponent
  },
  data: () => {
    return {
      url: require("./../../../../assets/images/sign-in.svg"),
      form: {
        email: null,
        password: null
      }
    };
  },
  validations: {
    form: {
      email: {
        required
      },
      password: {
        required
      }
    }
  },
  methods: {
    changeStep(step) {
      this.$store.dispatch("setModalStep", step);
    },
    submit() {
      this.$v.form.$touch();
      // if its still pending or an error is returned do not submit
      if (this.$v.form.$pending || this.$v.form.$error) return;
      // to form submit after this
      this.$store.dispatch("setModalState");

      /* this.$store
        .dispatch("sendRequestAccount", {
          _this: this,
          url:
            //process.env.VUE_APP_BASE_URL +
            getValidBaseUrl() +
            process.env.VUE_APP_BASE_URL_API +
            "/users",
          body: {
            email: this.form.email,
            password: this.form.paswword,
            lastName: this.form.lastName,
            message: this.form.message
          }
        })
        .then(() => {
          //TODO: I18N faire
          this.$notify({
            group: "form",
            type: "notification",
            title: "Connection",
            text: "Bienvenue sur la marketplace"
          });
          this.$store.dispatch("setModalState");
        })
        .catch(error => {
          console.log(error);
        });*/
    }
  }
};
</script>

<style scoped></style>
